export const config = {
    host: {
        "development": "http://localhost:8110/",
        "production": "https://api.svetlana.tsvetkov.one/"
    },
    api: {
        create: "api/create",
        update: "api/update",
        delete: "api/delete/",
        getach: "api/getach",
        getgal: "api/getgal",
        auth: "api/auth",
        getpar: "api/getpar"
    }
}