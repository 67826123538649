// Libraries, components, etc. //
import React from 'react';
import styled from 'styled-components';
import { config } from './env';
import axios from 'axios';
const NODE_ENV = process.env.NODE_ENV || 'production';

//#region Styles
const Page = styled.div`
	gap: 0.25rem;
	display: flex;
	margin: 2rem auto;
	padding: 2rem;
	background-color: white;
	overflow: auto;
    font-size: 1rem;

	@media (min-width: 320px) {
        flex-direction: column;
		width: 300px;
    }

	@media (min-width: 375px) {
        flex-direction: column;
		width: 350px;
    }

    @media (min-width: 600px) {
        flex-direction: column;
		width: 550px;
    }
`;
const Paragrapth = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
    font-size: 1rem;
    margin: 0;
`;
const Sign = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
	text-align: right;
	font-style: italic;
`;
const Image = styled.img`
    width: 24px;
`;
const Content = styled.div``;
const Title = styled.span`
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
`;
const ParagrapthTitle = styled.div`
    flex-direction: row;
    gap: .25rem;
    justify-content: center;
    display: flex;
`;
const ParagrapthText = styled.span`
    margin: auto 0;
`;
//#endregion

class Parents extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
            text: ''
		}
	}

	render() {
		const {text} = this.state;
		return (
			<Page>
				<Title>Родителям</Title>
                <plaintext>{text}</plaintext>
			</Page>
		);
	}

	componentDidMount() {
		/*
		fetch('parents.txt')
		.then(r => r.text())
		.then(text => {
			this.setState({text: text});
		});
		*/
		axios.get(config.host[NODE_ENV]+config.api.getpar)
            .then(res => {
                this.setState({
                    text: res.data.description
                })
            })
	}
}

export default Parents;
