// Libraries, components, etc. //
import React from 'react';
import styled from 'styled-components';

//#region Styles
const Page = styled.div`
	gap: 2rem;
	display: flex;
	margin: 2rem auto;
	padding: 2rem;
	background-color: white;
	overflow: auto;
    text-align:center;

	@media (min-width: 320px) {
        flex-direction: column;
		width: 300px;
    }

    @media (min-width: 500px) {
        flex-direction: column;
		width: 800px;
    }
`;
const Paragrapth = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
`;
const Sign = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
	text-align: right;
	font-style: italic;
`;
const Image = styled.img`
    width: 24px;
`;
const Content = styled.div``;
const Title = styled.span`
    text-align: center;
    font-size: 2rem;
`;
const ParagrapthTitle = styled.div`
    flex-direction: row;
    gap: .25rem;
    justify-content: center;
    display: flex;
`;
const ParagrapthText = styled.span`
    margin: auto 0;
`;
//#endregion

class Contact extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		}
	}

	render() {
		return (
			<Page>
				<Title>Обратная связь</Title>
                <Paragrapth>
                    <ParagrapthTitle>
                        <Image src='email.png'/>
                        <ParagrapthText>Электронная почта</ParagrapthText>
                    </ParagrapthTitle>
                    <br/>
                    <a href='mailto:school56izo@yandex.ru'>school56izo@yandex.ru</a>
                </Paragrapth>
			</Page>
		);
	}
}

export default Contact;
