// Libraries, components, etc. //
import React from 'react';
import styled from 'styled-components';

import { config } from './env';
import axios from 'axios';
const NODE_ENV = process.env.NODE_ENV || 'production';

//#region Styles
const Page = styled.div`
    gap: 2rem;
    justify-content: center;
    display: flex;
    width: 100vw;
    height: 100%;
    padding: 2rem;
    overflow: auto;

    @media (min-width: 320px) {
        flex-flow: row wrap;
    }

    @media (min-width: 500px) {
        flex-flow: column wrap;
    }
`;
const Item = styled.div`
    flex-direction: column;
    gap: 0.5rem;
    display: flex;
    max-width: 300px;
    background-color: white;
    font-family: 'Roboto', sans-serif;
`;
const Image = styled.img`
    max-width: 300px;
    object-fit: contain;

    @media (min-width: 320px) {
        max-width: 250px;
    }

    @media (min-width: 375px) {
        max-width: 300px;
    }

    @media (min-width: 500px) {
        max-width: 300px;
    }
`;
const Padding = styled.div`
    flex-direction: column;
    display: flex;
    padding: .5rem;
    padding-top: 0;
`;
const Title = styled.span`
    font-size: larger;
    color: black;
`;
const Subtitle = styled.span`
    color: grey;
    width: 100%;
`;
const PageName = styled.span`
    font-size: 2rem;
    color: white;
`;
//#endregion

class Achievements extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		}
	}

	render() {
		const {
            data
        } = this.state;

		return (
			<Page>
                <PageName>Достижения</PageName>
            {
                data && data.length > 0 && data.map((item, index) => 
                    <Item key={index}>
                        <Image src={item.image}/>
                        <Padding>
                            <Title>{item.title}</Title>
                            <Subtitle>{item.description}</Subtitle>
                        </Padding>
                    </Item>
                )
            }
			</Page>
		);
	}

	componentDidMount() {
        /*
		fetch('achievements.json',{
			headers: { 
			    'Content-Type': 'application/json',
			    'Accept': 'application/json'
		}})
		.then((response) => {return response.json()}).then((my) =>{
		    this.setState({data: my})
		});
        */
       axios.get(config.host[NODE_ENV]+config.api.getach)
       .then(res => {
           this.setState({data: res.data})
       })
	}
}

export default Achievements;
