// Libraries, components, etc. //
import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router";
import { ReactComponent as MenuFile} from './menu.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//#region Styles
const Page = styled.div`
	z-index: 20;
	flex-direction: row;
	gap: 1rem;
	display: flex;
	width: 100%;
	height: 50px;	
	padding: 1rem;
	background-color: rgba(255,255,255,1);

	@media (min-width: 320px) {
		height: 50px;
        flex-direction: column;
		align-items: center;
		padding: 0 1rem;
    }

    @media (min-width: 600px) {
        flex-direction: row;
		height: 80px;	
		padding: 1rem;
    }
`;
const CenterItem = styled.span`
	flex: 1;
	margin: auto 0;
	color: black;
	text-align: center;
	font-weight: 600;
	
    @media (min-width: 320px) {
        font-size: 1.5rem;
    }

    @media (min-width: 500px) {
        font-size: 2rem;
    }
`;
const HeaderItem = styled.span`
	margin: auto 0;
	text-transform: uppercase;
	font-size: larger;
	color: black;
    cursor: pointer;
`;
const Image = styled.img`
    width: 24px;
`;
const MobileHeader = styled.div`
	z-index: 20;
	flex-direction: row;
	align-items: center;
	display: flex;
	height: 50px;
	width: 100%;

	@media (min-width: 320px) {
        display: flex;
    }

	@media (min-width: 600px) {
        display: none;
    }
`;
const WebHeader = styled.div`
	flex-direction: row;
	gap: 1rem;
	display: flex;
	width: 100%;
	height: 80px;	
	padding: 1rem;
	background-color: rgba(255,255,255,1);
	
	@media (min-width: 320px) {
        display: none;
    }

	@media (min-width: 600px) {
        display: flex;
    }
`;
const MobileMenu = styled.div`
	position: absolute;
	gap: .5rem;
	justify-content: center;
	align-items: center;
	z-index: 20;
	top: 50px;
	left: 0;
	flex-direction: column;
	display: flex;
	width: 100vw;
	background-color: white;
	border-top: 1px solid lightgray;
	border-bottom: 1px solid lightgray;
	padding: 1rem;
`;
const MenuIcon = styled(MenuFile)`
    fill: ${props => props.fill};
`;
//#endregion

function Header(props) {
	const [showMenu, setShowMenu] = useState(false);
	const navigate = useNavigate();

	return (
		<Page>
			<MobileHeader>
				<MenuIcon width='24px' height='24px' onClick={() => setShowMenu(!showMenu)}/>
				<CenterItem>Светлана Барашкова</CenterItem>
				<HeaderItem onClick={()=> window.open("mailto:school56izo@yandex.ru", "_blank")}><Image src='email.png'/></HeaderItem>
			</MobileHeader>
			<WebHeader>
				<HeaderItem onClick={() => {navigate('/'); setShowMenu(false)}}>Обо мне</HeaderItem>
				<HeaderItem onClick={() => {navigate('/blog'); setShowMenu(false)}}>Галерея детских работ</HeaderItem>
				<HeaderItem onClick={() => {navigate('/achievements'); setShowMenu(false)}}>Достижения</HeaderItem>
				<HeaderItem onClick={() => {navigate('/parents'); setShowMenu(false)}}>Родителям</HeaderItem>
				<HeaderItem onClick={() => {navigate('/contact'); setShowMenu(false)}}>Обратная связь</HeaderItem>
				<CenterItem>Светлана Барашкова</CenterItem>
				<HeaderItem onClick={()=> window.open("mailto:school56izo@yandex.ru", "_blank")}><Image src='email.png'/></HeaderItem>
			</WebHeader>
			{
				showMenu && <MobileMenu>
					<HeaderItem onClick={() => {navigate('/'); setShowMenu(false)}}>Обо мне</HeaderItem>
					<HeaderItem onClick={() => {navigate('/blog'); setShowMenu(false)}}>Галерея детских работ</HeaderItem>
					<HeaderItem onClick={() => {navigate('/achievements'); setShowMenu(false)}}>Достижения</HeaderItem>
					<HeaderItem onClick={() => {navigate('/parents'); setShowMenu(false)}}>Родителям</HeaderItem>
					<HeaderItem onClick={() => {navigate('/contact'); setShowMenu(false)}}>Обратная связь</HeaderItem>
				</MobileMenu>
			}
		</Page>
	);
}

export default Header;
