// Libraries, components, etc. //
import axios from 'axios';
import React from 'react';
import styled from 'styled-components';
import { config } from './env';
const NODE_ENV = process.env.NODE_ENV || 'production';

//#region Styles
const Page = styled.div`
    gap: 2rem;
    display: flex;
    width: 100%;
    padding: 2rem;

    @media (min-width: 320px) {
        flex-direction: column;
    }

    @media (min-width: 500px) {
        flex-direction: row;
    }
`;
const ListSection = styled.div`
    flex-direction: column;
    gap: .5rem;
    flex: 2;
    display: flex;
    font-family: 'Roboto', sans-serif;
`;
const List = styled.div`
    flex-direction: column;
    gap: .5rem;
    flex: 2;
    overflow-y: auto;
    display: flex;
    font-family: 'Roboto', sans-serif;
`;
const Padding = styled.div`
    flex-direction: column;
    flex: 1;
    display: flex;
    padding: .5rem;
    padding-top: 0;
`;
const ListItem = styled.div`
    gap: .5rem;
    display: flex;
    background-color: white;
    width: 100%;
    cursor: pointer;
`;
const DeleteButton = styled.img`
    margin: auto 0;
    width: 32px;
    height: 32px;
`;
const FormTitle = styled.span`
    text-align: center;
    font-size: 2rem;
`;
const Form = styled.div`
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    display: flex;
    padding: 2rem;
    background-color: rgba(255,255,255,0.5);
`;
const Tabs = styled.div`
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    display: flex;
    padding: 0.5rem;
    background-color: rgba(255,255,255,0.5);
`;
const Tab = styled.span`
    margin: 0 auto;
    //color: ${props => props.selected ? '' : ''};
    font-weight: ${props => props.selected ? '600' : '400'};
    cursor: pointer;
`;
const Input = styled.input`
    padding: .5rem;
    outline: none;
`;
const Textarea = styled.textarea`
    padding: .5rem;
    outline: none;
`;
const ButtonRow = styled.div`
    gap: 1rem;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 0 1rem;
`;
const Message = styled.span`
    margin: auto;
    color: white;
    font-size: larger;
`;
const Image = styled.img`
    max-width: 100px;
    object-fit: contain;
`;
const PreviewImage = styled.img`
    max-width: 100px;
    object-fit: contain;
`;
//#endregion

class Admin extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dataGallery: [],
            dataAchievements: [],
            title: '',
            subtitle: '',
            access: false,
            file: null,
            selectedFile: null,
            previewImage: '',
            isEdit: false,
            showClear: false,
            tabIndex: 0,
            index: -1,
            text: '',
            id: -1,
            parId: -1
		}

        this._onChange = this._onChange.bind(this);
        this.add = this.add.bind(this);
        this.clear = this.clear.bind(this);
        this.delete = this.delete.bind(this);
        this.setItem = this.setItem.bind(this);
        this.setFile = this.setFile.bind(this);
        this.setTab = this.setTab.bind(this);
	}

	render() {
        const {
            access
        } = this.state;
        
        if (access === false) {
            return <Message>Ошибка доступа!</Message>;
        } 
        else {
            const {
                dataGallery,
                dataAchievements,
                title,
                subtitle,
                file,
                previewImage,
                isEdit,
                showClear,
                index,
                tabIndex,
                text
            } = this.state;

            return (
                <Page>
                    <ListSection>
                        <Tabs>
                            <Tab selected={tabIndex === 0} onClick={() => this.setTab(0)}>Галерея детских работ</Tab>
                            <Tab selected={tabIndex === 1} onClick={() => this.setTab(1)}>Достижения</Tab>
                            <Tab selected={tabIndex === 2} onClick={() => this.setTab(2)}>Родителям</Tab>
                        </Tabs>
                    <List>
                    {
					    tabIndex === 0 ? 
                        dataGallery && dataGallery.length > 0 && dataGallery.map((item, index) => 
                            <ListItem key={index}>
                                <Image src={item.image}/>
                                <Padding onClick={() => this.setItem(item, index)}>
                                    <span>{item.title}</span>
                                    <span>{item.description}</span>
                                </Padding>
                                <DeleteButton onClick={() => this.delete(item.id)} src='remove.png'/>
                            </ListItem>
                        )
                        : null
                    }
                    {
                        tabIndex === 1 ?
                        dataAchievements && dataAchievements.length > 0 && dataAchievements.map((item, index) => 
                        <ListItem key={index}>
                            <Image src={item.image}/>
                            <Padding onClick={() => this.setItem(item, index)}>
                                <span>{item.title}</span>
                                <span>{item.description}</span>
                            </Padding>
                            <DeleteButton onClick={() => this.delete(item.id)} src='remove.png'/>
                        </ListItem>
                    ) : null
                    }
                    {
                        tabIndex === 2 ?
                        <Textarea type='text' rows='10' id='text' value={text} onChange={(event) => this._onChange(event)}/>
                            : null
                    }    
				    {
                        tabIndex === 2 ?
                        <ButtonRow><button onClick={() => this.saveFile()}>Сохранить</button></ButtonRow>
                        : null
                    } 
                    </List>
                    </ListSection>
                    {
                        tabIndex !== 2 ?
<Form>
                        <FormTitle>{isEdit ? 'Изменить' : 'Добавить'} запись</FormTitle>
                        <PreviewImage src={previewImage}/>
                        <Input type='file' id='file' value={file} onChange={(event) => this.setFile(event)}/>
                        <Input type='text' id='title' placeholder='Заголовок' value={title} onChange={(event) => this._onChange(event)}/>
                        <Textarea type='text' id='subtitle' placeholder='Подзаголовок' value={subtitle} onChange={(event) => this._onChange(event)}/>
                        <ButtonRow>
                        {
                            showClear ?
                                <button onClick={() => this.clear()}>Отмена</button> : null
                        }
                            <button onClick={() => this.add(index)}>{isEdit ? 'Сохранить' : 'Добавить'}</button>
                        </ButtonRow>
                    </Form> : null
                    }
                    
                </Page>
            );
        }
	}

    clear() {
        this.setState({
            title: '',
            subtitle: '',
            file: null,
            selectedFile: null,
            previewImage: null,
            isEdit: false,
            showClear: false,
            index: -1,
            id: -1
        })
    }

    setTab(index) {
        this.clear();
        this.setState({tabIndex: index});
    }

    saveFile() {
        const {text, parId} = this.state;
        const addData = {
            id: parId,
            title: '',
            description: text
        }
        //axios.post('https://svetlana.tsvetkov.xyz:8110/api/savetext', {text: text})
        //axios.post('http://localhost:8110/api/savetext', {text: text})
        axios.put(config.host[NODE_ENV]+config.api.update, addData)
            .then(res => {
                
            })
    }

    setItem(data, index) {
        this.setState({
            title: data.title,
            subtitle: data.subtitle,
            previewImage: data.image,
            isEdit: true,
            showClear: true,
            index: index,
            id: data.id
        })
    }

    setFile(event) {
        this.setState({
            selectedFile: event.target.files[0],
            previewImage: URL.createObjectURL(event.target.files[0])
        });
    }

    delete(index) {
        const {
            dataGallery,
            dataAchievements,
            tabIndex
        } = this.state;
        
        if (tabIndex === 0) {
            //dataGallery.splice(index, 1);
            //axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpost', {data: dataGallery})
            axios.delete(config.host[NODE_ENV]+config.api.delete+index)
            .then(a =>  {
                axios.get(config.host[NODE_ENV]+config.api.getgal)
                    .then(res => {
                        this.clear();
                        this.setState({
                            dataGallery: res.data,
                            access: true
                        })
                    })
            })
            .catch(err => console.log('Произошла ошибка!'));
        }
        if (tabIndex === 1) {
            //dataAchievements.splice(index, 1);
            //axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpostach', {data: dataAchievements})
            //axios.post('http://localhost:8110/api/addpostach', {data: dataAchievements})
            axios.delete(config.host[NODE_ENV]+config.api.delete+index)
            .then(a =>  {
                axios.get(config.host[NODE_ENV]+config.api.getach)
            .then(res => {
                this.clear();
                this.setState({
                    dataAchievements: res.data,
                    access: true
                })
            })
            })
            .catch(err => console.log('Произошла ошибка!'));
        }
    }

    add() {
        const {
            dataGallery, 
            dataAchievements,
            title,
            subtitle,
            index,
            selectedFile,
            previewImage,
            tabIndex,
            id
        } = this.state;
        
        if (index === -1) {
            const sendData = new FormData();
            sendData.append('file', selectedFile);
            sendData.append('title', title);
            sendData.append('description', subtitle);
            if (tabIndex === 0) {
                sendData.append('type', 'gallery')
            }
            if (tabIndex === 1) {
                sendData.append('type', 'achievements')
            }
            axios.post(config.host[NODE_ENV]+config.api.create, sendData)
            .then(res => {
                this.clear();
                axios.get(config.host[NODE_ENV]+config.api.getach)
                .then(res => {
                    this.setState({
                        dataAchievements: res.data,
                        access: true
                    })
                })
                axios.get(config.host[NODE_ENV]+config.api.getgal)
                .then(res => {
                    this.setState({
                        dataGallery: res.data,
                        access: true
                    })
                })
            })
            /*
            axios.post('https://svetlana.tsvetkov.xyz:8110/api/upload', sendData)
            //axios.post('http://localhost:8110/api/upload', sendData)
            .then(res => {
                const addData = {
                    image: res.data.file,
                    title: title,
                    subtitle: subtitle
                }
                
                if (tabIndex === 0) {
                    dataGallery.push(addData);
                        axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpost', {data: dataGallery})
                    //axios.post('http://localhost:8110/api/addpost', {data: dataGallery})
                    .then(result =>  { window.location.reload();})
                    .catch(err => console.log('Произошла ошибка!'));
                }
                if (tabIndex === 1) {
                    dataAchievements.push(addData);
                    axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpostach', {data: dataAchievements})
                    //axios.post('http://localhost:8110/api/addpostach', {data: dataAchievements})
                    .then(result =>  { window.location.reload();})
                    .catch(err => console.log('Произошла ошибка!'));
                }
                    
            })
            .catch(err => console.log('Произошла ошибка!'));
            */
        }
        else {
            const addData = {
                id: id,
                title: title,
                description: subtitle
            }
            /*
            if (tabIndex === 0) {
                dataGallery[index] = addData;
                    axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpost', {data: dataGallery})
                //axios.post('http://localhost:8110/api/addpost', {data: dataGallery})
                .then(result =>  { window.location.reload();})
                .catch(err => console.log('Произошла ошибка!'));
            }
            if (tabIndex === 1) {
                dataAchievements[index] = addData;
                axios.post('https://svetlana.tsvetkov.xyz:8110/api/addpost', {data: dataAchievements})
                //axios.post('http://localhost:8110/api/addpostach', {data: dataAchievements})
                .then(result =>  { window.location.reload();})
                .catch(err => console.log('Произошла ошибка!'));
            }
            */
            axios.put(config.host[NODE_ENV]+config.api.update, addData)
            .then(res => {
                this.clear();
                axios.get(config.host[NODE_ENV]+config.api.getach)
                .then(res => {
                    this.setState({
                        dataAchievements: res.data,
                        access: true
                    })
                })
                axios.get(config.host[NODE_ENV]+config.api.getgal)
                .then(res => {
                    this.setState({
                        dataGallery: res.data,
                        access: true
                    })
                })
            })
        }
    }
    _onChange(event) {
        this.setState({
			[event.target.id]: event.target.value,
            showClear: true
		});
	}

	componentDidMount() {
        //let password = prompt("Введите пароль", "");
        //axios.post('https://svetlana.tsvetkov.xyz:8110/api/auth', {password: 'МойПароль17'})
        axios.post(config.host[NODE_ENV]+config.api.auth, {password: 'МойПароль17'})
        .then(res => {
            /*
            fetch('gallery.json',{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
            }})
            .then((response) => { return response.json() })
            .then((jsonData) =>{
                this.setState({
                    dataGallery: jsonData,
                    access: true
                })
            });
            fetch('achievements.json',{
                headers : { 
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
            }})
            .then((response) => { return response.json() })
            .then((jsonData) =>{
                this.setState({
                    dataAchievements: jsonData,
                    access: true
                })
            });
            */
            axios.get(config.host[NODE_ENV]+config.api.getach)
            .then(res => {
                this.setState({
                    dataAchievements: res.data,
                    access: true
                })
            })
            axios.get(config.host[NODE_ENV]+config.api.getgal)
            .then(res => {
                this.setState({
                    dataGallery: res.data,
                    access: true
                })
            })
            axios.get(config.host[NODE_ENV]+config.api.getpar)
            .then(res => {
                this.setState({
                    text: res.data.description,
                    parId: res.data.id
                })
            })
            /*
            fetch('parents.txt')
            .then(r => r.text())
            .then(text => {
                this.setState({text: text});
            });
            */
        })
        .catch(err => {
            this.setState({ access: false });
        })            
	}
}

export default Admin;
