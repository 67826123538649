// Libraries, components, etc. //
import React from 'react';
import styled from 'styled-components';

//#region Styles
const Page = styled.div`
	gap: 2rem;
	display: flex;
	margin: 2rem 2rem;
	padding: 2rem;
	background-color: white;
	overflow: auto;


	@media (min-width: 320px) {
        flex-direction: column;
		width: 100%;
		margin: 1rem 1rem;
		//width: 300px;
    }

	@media (min-width: 320px) {
        margin: 2rem 2rem;
		//width: 300px;
    }

    @media (min-width: 600px) {
        flex-direction: row;
		margin: 2rem auto;
		width: 600px;
    }
`;
const Paragrapth = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
`;
const Sign = styled.p`
	font-family: 'Roboto', sans-serif;
	color: black;
	text-align: right;
	font-style: italic;
`;
const Image = styled.img`
	object-fit: contain;
	width: 300px;

	@media (min-width: 320px) {
        margin: 0 auto;
		width: 200px;
    }

    @media (min-width: 500px) {
        margin: 0;
		width: 300px;
    }
`;
const Content = styled.div`
`;
//#endregion

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: []
		}
	}

	render() {
		return (
			<Page>
				<Image src='photo.jpeg'/>
				<Content>
					<Paragrapth>Художественное творчество влияет на развитие личности детей, формирует чуства, способность сопереживать, развивает эстетические чувства, стремление к прекрасному и как следствие стремление развивать в себе лучшие стороны. Рисование активизирует работу мозга, развивает концентрацию внимания и улучшает аналитическое мышление. Рисование снимает у детей чувство тревоги и повышает стрессоустойчивость, что очень важно из-за интеллектуальной перегрузки.</Paragrapth>
					<Paragrapth>Давайте вместре развивать творческие способности и творческий потенциал каждого ребенка. Ведь впереди их ждет жизнь полная общения и работы с детьми, а затем и взрослыми, с миром в целом.</Paragrapth>
					<Paragrapth>Мой девиз: люблю рисовать, люблю детей, люблю жизнь!</Paragrapth>
					<Sign>Барашкова С.А.<br/>учитель ИЗО в 5,6,78 классах</Sign>
				</Content>
			</Page>
		);
	}
}

export default Home;
