import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		font-family: 'Amatic SC', cursive;
		*, ::before, ::after {
			box-sizing: border-box;
		}
	}
`;

ReactDOM.render(
  <React.StrictMode>
    <Router>
		<GlobalStyle />
		<App/>
	</Router>
  </React.StrictMode>,
  document.getElementById('root')
);