import React from 'react';
import { Routes, Route } from "react-router-dom";
import styled from 'styled-components';
import Home from './home';
import Blog from './blog';
import Header from './header';
import Admin from './admin';
import Contact from './contact';
import Parents from './parents';
import Achievements from './achievements';

const Page = styled.div`
	flex-direction: column;
	display: flex;
	height: 100vh;
	background-image: url('bg.jpg');
	background-size: cover;
`;

const Content = styled.div`
	z-index: 10;
	display: flex;
	flex: 1;
	width: 100vw;
    height: 100%;
	overflow: auto;
`;
const Footer = styled.div`
	z-index: 10;
	display: flex;
	width: 100%;
	height: 50px;
	background-color: rgba(255,255,255,1);
	text-align: center;
`;
const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(#de6262, #ffb88c);
	opacity: 0.5;
`;
const CenterFooter = styled.span`
	margin: auto 0;
	flex: 1;
`;

class App extends React.Component {
	constructor(props) {
	super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Page>
				<Background />
				<Header />
				<Content>
					<Routes>
						<Route exact path="/" element={<Home/>}/>
						<Route path='/blog' element={<Blog/>}/>
						<Route path='/admin' element={<Admin/>}/>
						<Route path='/contact' element={<Contact/>}/>
						<Route path='/parents' element={<Parents/>}/>
						<Route path='/achievements' element={<Achievements/>}/>
					</Routes>
				</Content>
				<Footer>
					<CenterFooter>Designed by Ruslan Tsvetkov</CenterFooter>
				</Footer>
			</Page>
		);
	}
}

export default App;
